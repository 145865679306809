.date-selector {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 1px;
    background-color: rgb(90, 90, 90);
    color: white;
    border: 0;
    border-radius: 3px;
    width: 35px;
    flex: none;
    cursor: pointer;
  }

  .month {
      display: flex;
      flex: auto;
      justify-content: center;
      width: 1em;
  }

  .enter{
      width: fit-content;
      background-color: rgb(0, 112, 0);
  }
  

  .selected {
    background-color: rgb(129, 129, 129);
  }