.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: black;
  text-decoration: none;
}

.footer-content {
  font-size: small;
  color: white;
  cursor: pointer;
}
