.date-picker {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  opacity: 1;
}

.picker-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mode-selector {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}
.mode-button {
  width: 100%;
  height: 35px;
  font-size: large;
  font-weight: bold;
}

.picker-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 10px;
  width: 200px;
  height: 90vh;
}

.picker-header-content {
  font-size: x-large;
}

.guess-btn {
  font-size: xx-large;
  background-color: rgba(9, 255, 0, 0.815);
}

.guess-btn:active {
  background-color: yellow;
}

.date-picker:hover {
  opacity: 1;
}

.date-selector {
  width: 100%;
  flex-grow: 1;
  color: black;
  /* transition: background-color 0.5s;
  transition: color 0.5s; */
  /* transition: width 0.1s; */
}

/* .date-selector:hover { */
/* background-color: rgb(0, 0, 0);
  color: white; */
/* width: 100%; */
/* flex-grow: 2; */
/* } */
