body {
  margin: 0;
  background-color: black;
}

/*
Site Colors:
#d5e1ef - light blue
#191970 - dark blue

*/
