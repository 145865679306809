.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #111111;
  border-bottom: grey solid 3px;
}

.main-title {
  font-size: 3em;
  color: white;
  margin: 10px;
  margin-right: 5px;
  margin-left: 35px;
}

.mobile {
  font-size: 2em;
  margin-top: 0px;
  margin-bottom: 0px;
}

.betaText {
  color:white;
  font-weight: normal;
  font-size: 1em;
  margin-left: 5px;
  margin-right: 35px;
  align-self: flex-start;
}

.header-button {
  float: left;
  width: 75px;
  margin: 10px;
  padding: 8px;
  font-size: large;
  background-color: white;
  color: black;
  font-weight: bold;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
.header-button:hover {
  background-color: lightgray;
  cursor: pointer;
}