.score-screen {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0.9;
  background-color: black;

  color: white;
  font-size: x-large;
  font-family: "Courier New", Courier, monospace;
}

.retry-button {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.score {
  color: tan;
  margin-top: 0px;
}

.try-again {
  position: fixed;
  bottom: 0;
}
