.App {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center;
  max-width: 1440px;
  height: 100vh; */
}

.main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 90vh;
  background-color: grey;
}

