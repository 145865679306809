.keypad-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #111111;
  color: white;
  padding: 3px;
}

.month-button-container {
  display: flex;
  flex-direction: row;
  columns: 12;
  width: 100%;
  max-width: 600px;
}

.year-button-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
}
